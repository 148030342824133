import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import React from 'react';
import { Button } from 'wix-ui-tpa/cssVars';

import { IMAGE_ROUTE } from '../../../../../constants/image-route';
import settingsParams from '../../../settingsParams';
import { Layout } from '../../../stylesParams';
import { PageLink } from '../../../types';
import { useControllerProps, useWidgetText } from '../../hooks';
import { classes } from './StatusButton.st.css';

export const StatusButton: React.FC = () => {
  const { onNavigate, loyaltyProgram } = useControllerProps();
  const settings = useSettings();
  const { isRTL } = useEnvironment();
  const styles = useStyles();
  const { t } = useTranslation();
  const text = useWidgetText();

  const handleNavigate = () => {
    const pageLink = settings.get(settingsParams.pageLink) as PageLink | undefined;

    if (pageLink?.type === 'ExternalLink') {
      window.open(pageLink.url, pageLink.target || '_blank');
      return;
    }

    onNavigate(pageLink);
  };

  const iconPath = loyaltyProgram?.pointDefinition?.icon?.url ?? '';
  const showIcon = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.numbers.layout !== Layout.Text);
  const showText = styles
    .getStylesForAllBreakpoints()
    .some((breakpointStyles) => breakpointStyles.numbers.layout !== Layout.Icon);

  return (
    <Button dir={isRTL ? 'rtl' : undefined} fullWidth className={classes.root} onClick={handleNavigate}>
      <div className={classes.content}>
        {showIcon && (
          <img className={classes.icon} alt={t('status-widget.program-icon.alt-text')} src={IMAGE_ROUTE(iconPath)} />
        )}
        {showText && <span className={classes.text}>{text}</span>}
      </div>
    </Button>
  );
};
