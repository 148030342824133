import { createStylesParams, StyleParamType, wixColorParam, wixFontParam } from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';

export enum Layout {
  IconAndText = 0,
  Icon = 1,
  Text = 2,
}

export enum IconPosition {
  Start = 0,
  End = 1,
}

export enum Alignment {
  Start = 0,
  Center = 1,
  End = 2,
}

export type IStylesParams = {
  backgroundColor: StyleParamType.Color;
  textFont: StyleParamType.Font;
  textColor: StyleParamType.Color;
  borderColor: StyleParamType.Color;
  borderWidth: StyleParamType.Number;
  cornerRadius: StyleParamType.Number;
  backgroundColorHover: StyleParamType.Color;
  textColorHover: StyleParamType.Color;
  borderColorHover: StyleParamType.Color;
  layout: StyleParamType.Number;
  iconPosition: StyleParamType.Number;
  iconSize: StyleParamType.Number;
  alignment: StyleParamType.Number;
  gap: StyleParamType.Number;
};

export default createStylesParams<IStylesParams>({
  backgroundColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  textFont: {
    type: StyleParamType.Font,
    getDefaultValue: wixFontParam('Body-M', {
      size: 16,
    }),
  },
  textColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8'),
  },
  borderColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0),
  },
  borderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 1,
  },
  cornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 8,
  },
  backgroundColorHover: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 0),
  },
  textColorHover: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  borderColorHover: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0),
  },
  layout: {
    type: StyleParamType.Number,
    getDefaultValue: ({ isMobile }) => (isMobile ? Layout.Icon : Layout.IconAndText),
  },
  iconPosition: {
    type: StyleParamType.Number,
    getDefaultValue: () => IconPosition.Start,
  },
  iconSize: {
    type: StyleParamType.Number,
    getDefaultValue: () => 24,
  },
  alignment: {
    type: StyleParamType.Number,
    getDefaultValue: () => Alignment.Start,
  },
  gap: {
    type: StyleParamType.Number,
    getDefaultValue: () => 12,
  },
});

const contentAlignmentMap: Record<Alignment, string> = {
  [Alignment.Start]: 'flex-start',
  [Alignment.Center]: 'center',
  [Alignment.End]: 'flex-end',
};

export const customCssVars: CustomCssVarsFn = (params) => {
  const { styleParams } = params;
  const showIcon = styleParams.numbers.layout !== Layout.Text;
  const showText = styleParams.numbers.layout !== Layout.Icon;
  const showGap = styleParams.numbers.layout === Layout.IconAndText;

  return {
    iconDisplay: showIcon ? 'block' : 'none',
    textDisplay: showText ? 'block' : 'none',
    iconSize: `${styleParams.numbers.iconSize}px`,
    iconTextGap: showGap ? `${styleParams.numbers.gap}px` : '0',
    contentDirection: styleParams.numbers.iconPosition === IconPosition.Start ? 'row' : 'row-reverse',
    contentAlignment: contentAlignmentMap[styleParams.numbers.alignment as Alignment],
  };
};
